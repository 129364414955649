.admin-panel-login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin-top: 10%;
}

.auth-logo-container img {
  /* width: "4rem";
  height: "4rem"; */
  /* margin-bottom: "5rem"; */
  object-fit: contain;
}

.sign-up-container h4 {
  cursor: pointer;
  text-decoration: underline;
  color: brown;
}

.forget-up-container h4 {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  color: brown;
}
