.role-name-column-header {
  padding-left: 4rem !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4e4b66;
}

.type-name-column-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.action-column-header {
  padding-right: 6rem !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4e4b66;
}

.product-list-image img {
  width: 4rem;
  height: 4rem;
}

.server-roles-trash-btn img {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.server-role-even-row {
  background-color: #fff;
}

.server-role-odd-row {
  background: #d9e7ef;
}

.admin-products-main-container {
  background-color: #fff;
  padding: 0 2.4rem;
}

.bread-crumb {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.configure-server-home-icon img {
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
}

.configure-server-roles-main-heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.7rem;
}

.configure-server-roles-main-heading-container h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  color: #06141c;
}

.server-roles-tb-main-container {
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0px 0px 8px 1px rgba(9, 161, 218, 0.1);
  margin-top: 1.5rem;
}

.services-background-image img {
  width: 8rem;
  height: 8rem;
}
.service-background-option img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
