.server-roles-tb-main-container-editor {
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0px 0px 8px 1px rgba(9, 161, 218, 0.1);
  margin-top: 1.5rem;
}

.demo-wrapper {
  border: 1px solid #f1f1f1;
  padding: 10px;
  border-radius: 4px;
}

.demo-editor {
  min-height: 300px;
}

.html-render {
  margin-top: 2rem;
  padding: 2rem;
}

.html-render-h1 h1 {
  margin-bottom: 2rem;
}
