.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.modal-content {
  background: white;
  padding: 4rem;
  border-radius: 4px;
  position: relative;
  width: 40rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.add-expert-modal-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}
