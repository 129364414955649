.side-bar-icon {
  width: 3rem;
  height: 3rem;
}

.icon {
  filter: invert(100%);
  width: 3rem;
  height: 3rem;
}

.log-out {
  width: 2rem;
  height: 2rem;
}

.main-logo {
  width: 8rem;
  height: 12rem;
  object-fit: contain;
}

.ant-menu-item-selected {
  background-color: #5b4f58 !important;
  color: #fff;
}
